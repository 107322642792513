import apiFetch from './apiFetch'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { positionMarkdownsSlice } from 'slices/positionMarkdownsSlice'

export default createAsyncThunk(
  'positionMarkdowns/positionMarkdownDeleted',
  async (
    params: { id: number; position_id: number },
    { dispatch, rejectWithValue, signal },
  ) => {
    const response = ((await dispatch(
      apiFetch(`/position_markdowns/${params.id}`, {
        method: 'DELETE',
        signal,
      }),
    )) as unknown) as Response

    await response.body
    if (!response.ok) {
      return rejectWithValue(response.status)
    }
    const { positionMarkdownDeleted } = positionMarkdownsSlice.actions

    dispatch(positionMarkdownDeleted(params))
  },
)
