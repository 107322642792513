import {
  FETCH,
  FETCH_ERROR,
  FETCH_RECEIVE,
  FETCH_REQUEST,
  FetchAction,
  FetchErrorAction,
  FetchReceiveAction,
  FetchRequestAction,
} from '../types'

export const apiFetchError = ({
  status,
  statusText,
}: {
  status: number
  statusText: string
}): FetchErrorAction => ({
  type: FETCH_ERROR,
  status,
  statusText,
})

export const apiFetchReceive = (): FetchReceiveAction => ({
  type: FETCH_RECEIVE,
})
export const apiFetchRequest = (): FetchRequestAction => ({
  type: FETCH_REQUEST,
})

export default (path: string, options = {}): FetchAction => ({
  type: FETCH,
  path,
  options,
})
