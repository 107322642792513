import React from 'react'
import useActiveNavItemId from '../../hooks/useActiveNavItemId'

const HomeScene = () => {
  useActiveNavItemId('dashboard')
  return (
    <>
      <h1>Welcome to CivicEngine Dashboard</h1>
    </>
  )
}

export default HomeScene
