import { createSlice } from '@reduxjs/toolkit'
import { TenantMembership } from 'types'
import usersAdapter from '../adapters/usersAdapter'
import tenantMembershipsSlice from './tenantMembershipsSlice'
import tenantsSlice from './tenantsSlice'

export default createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState(),
  reducers: {
    usersReceived(state, action) {
      usersAdapter.addMany(state, action.payload.users)
    },
    userAdded: usersAdapter.addOne,
  },
  extraReducers: {
    [tenantsSlice.actions.tenantsReceived.toString()]: (state, action) => {
      usersAdapter.addMany(state, action.payload.users || [])
    },
    [tenantMembershipsSlice.actions.tenantMembershipsReceived.toString()]: (
      state,
      action,
    ) => {
      action.payload.tenant_memberships?.forEach(
        (tenantMembership: TenantMembership) => {
          const userId = tenantMembership.user_id
          const newTenantMemberships = state.entities[
            userId
          ]?.tenant_memberships.concat(tenantMembership)
          usersAdapter.updateOne(state, {
            id: userId,
            changes: {
              tenant_memberships: newTenantMemberships,
            },
          })
        },
      )
    },
  },
})
