import React from 'react'
import styles from './searchBar.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import classNames from 'classnames'

interface Props {
  className?: string
  onClick: () => void
}

const SearchBar = ({ className, onClick }: Props) => {
  return (
    <button
      onClick={onClick}
      className={classNames(styles.container, className)}
    >
      <FontAwesomeIcon className={styles.icon} icon={faSearch} />
      Search
    </button>
  )
}

export default SearchBar
