import { schema } from 'normalizr'
import {
  Campaign,
  CandidateMarkdown,
  Candidate,
  Election,
  Education,
  Experience,
  Message,
  Tenant,
  Candidacy,
  MessageTranslation,
  PositionMarkdown,
  User,
  TenantMembership,
  Organization,
  Position,
  Endorsement,
} from './types'

export let messageSchema: schema.Entity<Message>
export let eventSchema: schema.Entity<Event>
export let campaignSchema: schema.Entity<Campaign>
export let campaignListSchema: schema.Array<Campaign[]>
export let candidateSchema: schema.Entity<Candidate>
export let electionSchema: schema.Entity<Election>
export let positionSchema: schema.Entity<Position>
export let tenantListSchema: schema.Array<Tenant[]>
export let tenantSchema: schema.Entity<Tenant>
export let candidacySchema: schema.Entity<Candidacy>
export let candidateMarkdownSchema: schema.Entity<CandidateMarkdown>
export let educationSchema: schema.Entity<Education>
export let experienceSchema: schema.Entity<Experience>
export let candidateListSchema: schema.Array<Candidate[]>
export let candidacyListSchema: schema.Array<Candidacy[]>
export let messageTranslationSchema: schema.Entity<MessageTranslation>
export let positionMarkdownSchema: schema.Entity<PositionMarkdown>
export let positionListSchema: schema.Array<Position[]>
export let userSchema: schema.Entity<User>
export let electionListSchema: schema.Array<Election[]>
export let userListSchema: schema.Array<User[]>
export let tenantMembershipSchema: schema.Entity<TenantMembership>
export let tenantMembershipListSchema: schema.Array<TenantMembership[]>
export let organizationSchema: schema.Entity<Organization>
export let organizationListSchema: schema.Array<Organization[]>
export let endorsementSchema: schema.Entity<Endorsement>
export let endorsementListSchema: schema.Array<Endorsement[]>

messageTranslationSchema = new schema.Entity('messageTranslations', {
  message: messageSchema!,
})

messageSchema = new schema.Entity('messages', {
  event: eventSchema!,
  message_translations: [messageTranslationSchema],
})

eventSchema = new schema.Entity('events', {
  campaign: campaignSchema!,
  messages: [messageSchema],
})

campaignSchema = new schema.Entity('campaigns', {
  events: [eventSchema],
})

campaignListSchema = new schema.Array(campaignSchema)
candidateMarkdownSchema = new schema.Entity('candidate_markdowns', {
  candidate: candidateSchema!,
})
educationSchema = new schema.Entity('education', {
  candidate: candidateSchema!,
})

experienceSchema = new schema.Entity('experience', {
  candidate: candidateSchema!,
})

candidacySchema = new schema.Entity('candidacies')

candidateSchema = new schema.Entity('candidates', {
  candidacies: [candidacySchema],
  candidate_markdowns: [candidateMarkdownSchema],
  education: [educationSchema],
  experience: [experienceSchema],
})

candidateListSchema = new schema.Array(candidateSchema)
positionSchema = new schema.Entity('positions')

positionSchema = new schema.Entity('positions')

candidacySchema = new schema.Entity('candidacies', {
  position: positionSchema,
  candidate: candidateSchema,
})

candidacyListSchema = new schema.Array(candidacySchema)

positionMarkdownSchema = new schema.Entity('position_markdowns', {
  position: positionSchema!,
})

positionSchema = new schema.Entity('positions', {
  candidacies: [candidacySchema],
  position_markdowns: [positionMarkdownSchema],
})

positionListSchema = new schema.Array(positionSchema)

electionSchema = new schema.Entity('elections', {
  positions: [positionSchema],
  candidacies: [candidacySchema],
  tenant: tenantSchema!,
})

electionListSchema = new schema.Array(electionSchema)

// userSchema = new schema.Entity('users')

tenantMembershipSchema = new schema.Entity('tenant_memberships', {
  tenant: tenantSchema!,
  user: userSchema!,
})
tenantMembershipListSchema = new schema.Array(tenantMembershipSchema)

tenantSchema = new schema.Entity('tenants', {
  elections: [electionSchema],
  tenant_memberships: [tenantMembershipSchema],
})

userSchema = new schema.Entity('users', {
  tenant_memberships: [tenantMembershipSchema],
})

userListSchema = new schema.Array(userSchema)

// export const tenants = new schema.Array(tenantSchema)
tenantListSchema = new schema.Array(tenantSchema)

organizationSchema = new schema.Entity('organizations')

organizationListSchema = new schema.Array(organizationSchema)

endorsementSchema = new schema.Entity('endorsements', {
  candidacy: candidacySchema,
  candidate: candidateSchema!,
})

endorsementListSchema = new schema.Array(endorsementSchema)
