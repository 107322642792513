import { createSlice } from '@reduxjs/toolkit'
import experienceAdapter from '../adapters/experienceAdapter'
import candidatesSlice from './candidatesSlice'

export default createSlice({
  name: 'education',
  initialState: experienceAdapter.getInitialState(),
  reducers: {
    educationReceived(state, action) {
      experienceAdapter.addMany(state, action.payload.experience)
    },
  },
  extraReducers: {
    [candidatesSlice.actions.candidatesReceived.toString()]: (
      state,
      action,
    ) => {
      experienceAdapter.addMany(state, action.payload.experience || [])
    },
  },
})
