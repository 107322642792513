import React from 'react'
import classNames from 'classnames'
import styles from './Spinner.module.scss'

interface Props {
  size: 'lg' | 'sm'
}

const Spinner: React.FC<Props> = ({ size }) => (
  <div
    className={classNames(styles.root, {
      [styles[size]]: size,
    })}
  />
)

export default Spinner
