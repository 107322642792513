import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import styles from './EditPositionRow.module.scss'
import { Locale, Position } from 'types'
import Button from 'components/TopNav/UserMenu/Button/Button'
import SelectField from 'components/TopNav/SearchForm/SelectField/SelectField'
import { Field, Form, Formik } from 'formik'
import { locales } from 'utils/locales'
import { Styles } from 'react-select'

export interface Props {
  customDescription: string | null
  customName: string | null
  position: Position
  locale: string
  setEditing: (editing: boolean) => void
  handleSubmit: (values: any) => void
  updateLocale: (locale: string) => void
}
interface OptionType {
  label: string
  value: string
}

const customStyles: Partial<Styles> = {
  indicatorSeparator: () => ({}),
  indicatorsContainer: (provided: CSSProperties) => ({
    ...provided,
    paddingRight: '1rem',
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    border: '1px solid #EAEAEA',
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    border: '1px solid #EAEAEA',
    boxShadow: '0 2px 4px 0 rgba(177,177,177,0.5)',
    marginTop: '-1px',
  }),
}

const EditPositionRow: React.FC<Props> = ({
  customName,
  customDescription,
  position,
  locale,
  setEditing,
  handleSubmit,
  updateLocale,
}) => {
  const options: OptionType[] = locales
    .map((locale: Locale) => {
      return { label: locale.name, value: locale.key }
    })
    .sort((localeA, localeB) => (localeA.label > localeB.label ? 1 : -1))
  const initialValues = {
    customName: customName || '',
    customDescription: customDescription || '',
    locale: locale,
  }

  return (
    <tr>
      <td>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ setFieldValue, values, handleChange, handleBlur }) => {
            const handleLocaleChange = (option: OptionType) => {
              setFieldValue('locale', option.value)
              updateLocale(option.value)
            }
            return (
              <Form className={classNames(styles.editPositionRow)}>
                <div
                  className={classNames(
                    styles.originalName,
                    styles.positionItem,
                  )}
                >
                  {position.name}
                </div>
                <div className={(styles.locale, styles.positionItem)}>
                  <label className={styles.label}>Language</label>
                  <Field
                    component={SelectField}
                    name="locale"
                    options={options}
                    value={values.locale}
                    className={styles.localeSelect}
                    customStyles={customStyles}
                    onChange={handleLocaleChange}
                  />
                </div>
                <div
                  className={classNames(styles.customName, styles.positionItem)}
                >
                  <label className={styles.label}>Position</label>
                  <input
                    name="customName"
                    className={classNames(styles.input, styles.nameInput)}
                    value={values.customName}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={classNames(
                    styles.customDescription,
                    styles.positionItem,
                  )}
                >
                  <label className={styles.label}>Description</label>
                  <textarea
                    name="customDescription"
                    value={values.customDescription}
                    onChange={handleChange}
                    className={classNames(styles.input, styles.descriptionText)}
                  />
                </div>
                <div className={styles.buttons}>
                  <Button
                    className={classNames(
                      styles.cancelButton,
                      styles.positionItem,
                    )}
                    onClick={() => setEditing(false)}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classNames(
                      styles.saveButton,
                      styles.positionItem,
                    )}
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </td>
    </tr>
  )
}

export default EditPositionRow
