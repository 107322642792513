import React from 'react'
import classNames from 'classnames'
import styles from './Sidebar.module.scss'
import { SidebarItemCollection } from '../../types'
import Sub from './Sub/Sub'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../rootReducer'
import TenantSwitcher from './TenantSwitcher/TenantSwitcher'

interface Props extends RouteComponentProps {
  className?: string
  superuser: boolean
}

const Sidebar = ({ className, history, superuser }: Props) => {
  const activeNavItemId = useSelector(
    ({ activeNavItemId }: RootState) => activeNavItemId,
  )

  const items: SidebarItemCollection = [
    { id: 'dashboard', type: 'link', title: 'Dashboard', to: '/' },
    {
      id: 'engines',
      type: 'link',
      title: 'Engines',
      to: '/engines',
    },
    {
      type: 'submenu',
      title: 'Data Review',
      items: [
        {
          id: 'positions',
          type: 'link',
          title: 'Positions',
          to: '/data/positions',
        },
        {
          id: 'endorsements',
          type: 'link',
          title: 'Endorsements',
          to: '/data/endorsements',
        },
      ],
    },
    {
      type: 'submenu',
      title: 'Outreach',
      items: [
        {
          id: 'campaigns',
          type: 'link',
          title: 'Campaigns',
          to: '/outreach/campaigns',
        },
      ],
    },
    {
      superuserOnly: true,
      type: 'submenu',
      title: 'Administration',
      items: [
        { id: 'tenants', type: 'link', title: 'Tenants', to: '/admin/tenants' },
        { id: 'users', type: 'link', title: 'Users', to: '/admin/users' },
      ],
    },
  ]

  return (
    <aside className={classNames(styles.root, className)}>
      <nav className={styles.nav}>
        {items.map((item, i) => {
          if (!superuser && item.superuserOnly) {
            return null
          }

          switch (item.type) {
            case 'link':
              return (
                <Link
                  className={classNames(styles.link, {
                    [styles.activeLink]: item.id === activeNavItemId,
                  })}
                  key={i}
                  to={item.to}
                >
                  {item.title}
                </Link>
              )
            case 'submenu':
              return (
                <Sub
                  {...item}
                  currentPath={history.location.pathname}
                  key={i}
                />
              )
            default:
              return null
          }
        })}
      </nav>

      <TenantSwitcher />
    </aside>
  )
}

export default withRouter(Sidebar)
