import React, { CSSProperties } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select, { ValueType, Styles } from 'react-select'
import { RootState } from 'rootReducer'
import { User, Election } from 'types'
import useAuth0 from 'hooks/useAuth0'
import styles from './ElectionSwitcher.module.scss'
import updateCurrentUser from 'actions/updateCurrentUser'
import classNames from 'classnames'
import DropdownIndicator from '../DropdownIndicator/DropdownIndicator'
import setActiveElectionId from 'actions/setActiveElectionId'

interface Props {
  className?: string
}

type OptionType = {
  label: string
  value: number
}

const customStyles: Partial<Styles> = {
  option: (provided: CSSProperties, state: { isSelected: boolean }) => ({
    ...provided,
    border: '0px',
    color: state.isSelected ? 'black' : '#9B9B9B',
    fontFamily: 'Lato',
    fontWeight: 900,
    padding: '0.5rem 1rem',
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    border: '0px',
    boxShadow: '0px',
    width: '100%',
    cursor: 'pointer',
    backgroundColor: 'inherit',
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    border: '0px',
    boxShadow: '0 2px 4px 0 rgba(177,177,177,0.5)',
    marginTop: '-6px',
    marginLeft: '-1rem',
    overflow: 'auto',
  }),
  menuList: (provided: CSSProperties) => ({
    ...provided,
    borderTop: '1px solid #DEDEDE',
  }),
  indicatorSeparator: () => ({}),
  container: (provided: CSSProperties) => ({
    ...provided,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    cursor: 'pointer',
    minWidth: '18rem',
  }),
  valueContainer: (provided: CSSProperties) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided: CSSProperties) => ({
    ...provided,
    margin: 0,
  }),
}

const ElectionSwitcher = ({ className }: Props) => {
  const dispatch = useDispatch()
  const { loginWithRedirect } = useAuth0()
  const currentUser = useSelector<RootState, User | null>(
    ({ currentUser }) => currentUser,
  )

  const electionId = currentUser?.election_id
  let { elections } = useSelector(({ entities: { elections } }: RootState) => ({
    elections: elections.entities,
  }))

  if (!currentUser) {
    return null
  }

  const handleChange = async (selectedOption: ValueType<OptionType>) => {
    const electionId = (selectedOption as OptionType).value

    await dispatch(
      updateCurrentUser({
        election_id: electionId,
      }),
    )
    // Redirect so Auth0 user is refreshed
    loginWithRedirect()
  }

  const options = (Object.values(elections) as Election[]).map((election) => ({
    value: election.id,
    label: `${new Date(
      `${election.election_day}T12:00:00`, // This a hack, but so are dates in general and this should cover all the US
    ).toLocaleDateString('en-US')} ${election.name.replace('Election', '')}`,
  }))

  const selectedOption = electionId
    ? options.find((o) => o.value === electionId)
    : null

  return (
    <div className={classNames(styles.electionSwitcher, className)}>
      <Select
        components={{ DropdownIndicator }}
        options={options}
        onChange={handleChange}
        value={selectedOption}
        styles={customStyles}
        placeholder={'Election'}
      />
    </div>
  )
}

export default ElectionSwitcher
