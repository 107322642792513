import { Action } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import thunkMiddleware, { ThunkAction } from 'redux-thunk'
import { rootReducer, RootState } from './rootReducer'
import dashboardApiMiddleware from './middleware/dashboardApiMiddleware'

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export default configureStore({
  middleware: [dashboardApiMiddleware(), thunkMiddleware],
  reducer: rootReducer,
})
