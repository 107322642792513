import React from 'react'
import Button from './Button/Button'
import useAuth0 from 'hooks/useAuth0'
import TopNavMenu from '../TopNavMenu/TopNavMenu'
import styles from './UserMenu.module.scss'

interface Props {
  className?: string
}

const UserMenu: React.FC<Props> = ({ className }: Props) => {
  const { logout, user: authUser } = useAuth0()

  if (!authUser) {
    return null
  }

  return (
    <TopNavMenu
      className={className}
      buttonChildren={() => (
        <>
          <img
            className={styles.picture}
            src={authUser.picture}
            alt="Profile"
          />
          {authUser.name}
        </>
      )}
    >
      <Button variant={'white'} onClick={() => logout()}>
        Sign out
      </Button>
    </TopNavMenu>
  )
}

export default UserMenu
