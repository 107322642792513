// src/components/PrivateRoute.js

import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import useAuth0 from 'hooks/useAuth0'

export interface Props {
  component: any
  path: any
  exact?: boolean
  superuser?: boolean
}

const PrivateRoute = ({
  component: Component,
  exact,
  path,
  superuser = false,
  ...rest
}: Props) => {
  const { loading, isAuthenticated, loginWithRedirect, user } = useAuth0()

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      })
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect, path])

  const render = (props: any) => {
    if (!isAuthenticated) {
      return null
    } else if (
      superuser &&
      user &&
      !user['https://ballotready.org/claims/superuser']
    ) {
      return null
    } else {
      return <Component {...props} />
    }
  }

  return <Route path={path} exact={exact} render={render} {...rest} />
}

export default PrivateRoute
