import React, { ErrorInfo } from 'react'
import * as Sentry from '@sentry/browser'

interface Props {}

interface State {
  eventId: string | undefined
  hasError: boolean
}

class AppErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      eventId: undefined,
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    const { children } = this.props
    const { eventId, hasError } = this.state

    if (hasError) {
      return (
        <>
          <h1>Something went wrong</h1>
          <button onClick={() => Sentry.showReportDialog({ eventId })}>
            Report feedback
          </button>
        </>
      )
    }

    return children
  }
}

export default AppErrorBoundary
