import { normalize, schema } from 'normalizr'

export default (json: any, mySchema: schema.Entity | schema.Array) => {
  const normalized = normalize(json, mySchema)
  let fixed: { [key: string]: any[] } = {}
  for (let [key, value] of Object.entries(normalized.entities)) {
    if (value) {
      fixed[key] = Object.values(value)
    }
  }

  return fixed
}
