import { useEffect, RefObject } from 'react'

type OutSideClickCallback = (e: MouseEvent) => void

/**
 * Hook that alerts clicks outside of the passed ref
 */
export default (
  ref: RefObject<HTMLDivElement>,
  onClickOutside: OutSideClickCallback,
) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!ref?.current?.contains(e.target as HTMLDivElement)) {
        onClickOutside(e)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClickOutside, ref])
}
