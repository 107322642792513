import { createAsyncThunk } from '@reduxjs/toolkit'
import apiFetch from './apiFetch'

export default createAsyncThunk(
  'currentUser/update',
  async (
    params: { election_id: number },
    { dispatch, rejectWithValue, signal },
  ) => {
    const response = ((await dispatch(
      apiFetch(`/user`, {
        method: 'PATCH',
        body: JSON.stringify({ user: { election_id: params.election_id } }),
        signal,
      }),
    )) as unknown) as Response

    const json = await response.json()

    if (!response.ok) {
      return rejectWithValue(json)
    }

    return json
  },
)
