import React, { useEffect, lazy, Suspense } from 'react'
import { Link, Router, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import history from 'browserHistory'
import useAuth0 from 'hooks/useAuth0'
import PrivateRoute from 'components/PrivateRoute/PrivateRoute'
import HomeScene from 'components/HomeScene/HomeScene'
import Sidebar from 'components/Sidebar/Sidebar'
import TopNav from 'components/TopNav/TopNav'
import LogoImage from 'assets/images/logo.svg'
import { RootState } from 'rootReducer'
import fetchCurrentUser from 'actions/fetchCurrentUser'
import { User } from 'types'
import styles from './Main.module.scss'
import MessageScene from 'components/MessageScene/MessageScene'
import Emoji from 'components/Emoji/Emoji'
import fetchElections from 'actions/fetchElections'
import SearchResultsScene from 'components/HomeScene/SearchResultsScene/SearchResultsScene'

const AdminApp = lazy(() => import('components/AdminApp/AdminApp'))
const DataReviewApp = lazy(() =>
  import('components/DataReviewApp/DataReviewApp'),
)
const OutreachApp = lazy(() => import('components/OutreachApp/OutreachApp'))
const EngineApp = lazy(() => import('components/EngineApp/EngineApp'))

const Main: React.FC = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector<RootState, User | null>(
    ({ currentUser }) => currentUser,
  )
  const { loading, user, isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (isAuthenticated && !currentUser) {
      dispatch(fetchCurrentUser())
      dispatch(fetchElections())
    }
  }, [currentUser, dispatch, isAuthenticated])

  if (loading) {
    return (
      <MessageScene spinner>
        Authenticating <Emoji emoji="😊" label="relaxed" />
      </MessageScene>
    )
  } else if (!isAuthenticated) {
    loginWithRedirect()
    return null
  } else if (!currentUser) {
    return (
      <MessageScene spinner>
        Loading user data <Emoji emoji="🙂" label="relaxed" />
      </MessageScene>
    )
  } else if (currentUser.tenant_memberships.length === 0) {
    return (
      <MessageScene>
        <h1>
          No memberships found <Emoji emoji="😵" label="unamused" />
        </h1>
        <p>
          It appears your user account is not configured to access data. Please
          contact an administrator to restore your account.
        </p>
      </MessageScene>
    )
  } else {
    return (
      <Router history={history}>
        <Suspense fallback={<div>Loading...</div>}>
          <div className={styles.root}>
            <Link to="/" className={styles.logo}>
              <img src={LogoImage} alt="Logo" />
            </Link>

            <TopNav className={styles.nav} />
            {user && (
              <Sidebar
                className={styles.sidebar}
                superuser={user['https://ballotready.org/claims/superuser']}
              />
            )}
            <main className={styles.main}>
              <Switch>
                <PrivateRoute exact path="/" component={HomeScene} />
                <PrivateRoute path="/engines" component={EngineApp} />
                <PrivateRoute superuser path="/admin" component={AdminApp} />
                <PrivateRoute path="/data" component={DataReviewApp} />
                <PrivateRoute path="/outreach" component={OutreachApp} />
                <PrivateRoute path="/search" component={SearchResultsScene} />
              </Switch>
            </main>
          </div>
        </Suspense>
      </Router>
    )
  }
}

export default Main
