import React from 'react'
import classNames from 'classnames'
import { Candidate } from '../../../../../types'
import styles from './Headshot.module.scss'
import HeadshotImage from 'assets/images/headshot.png'
export interface Props {
  alt?: string
  candidate: Candidate
  responsive?: boolean
  shadow?: boolean
  size?: string
}

const Headshot: React.FC<Props> = ({
  alt,
  candidate,
  responsive = true,
  shadow = true,
  size,
}) => (
  <img
    alt={alt || 'alt'}
    className={classNames(
      styles.Headshot,
      responsive ? styles.responsive : '',
      shadow ? styles.shadow : '',
      size === 'lg' && styles.lg,
      size === 'xs' && styles.xs,
    )}
    src={
      candidate.thumb_url.indexOf('.jpg') > -1
        ? candidate.thumb_url
        : HeadshotImage
    }
  />
)

export default Headshot
