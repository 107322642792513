import React from 'react'
import styles from './CandidateRow.module.scss'
import { Candidate, Position } from 'types'
import Headshot from 'components/DataReviewApp/CandidateScene/CandidateInfo/Headshot/Headshot'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'rootReducer'

interface Props {
  candidate: Candidate
  position?: Position
}
const CandidateRow: React.FC<Props> = ({ candidate, position }) => {
  const currentCandidacyId = candidate.candidacies[0]
  let { electionId } = useSelector(({ currentUser }: RootState) => ({
    electionId: currentUser?.election_id,
  }))

  if (
    currentCandidacyId === undefined ||
    currentCandidacyId === null ||
    position === undefined
  ) {
    return null
  }
  return (
    <tr className={styles.candidateRow} key={candidate.id}>
      <td>
        <Headshot candidate={candidate} />
      </td>
      <td className={styles.candidateInfo}>
        <Link
          className={styles.candidateLink}
          to={`/data/positions/${position.id}/candidates/${candidate.id}?electionId=${electionId}`}
        >
          {candidate.first_name} {candidate.last_name}
        </Link>
        <div>{position.name}</div>
      </td>
    </tr>
  )
}

export default CandidateRow
