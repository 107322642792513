// src/react-auth0-spa.js
import createAuth0Client from '@auth0/auth0-spa-js'
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client'

let _client: Auth0Client

const getAuth0Client = (): Promise<Auth0Client> => {
  return new Promise(async (resolve, reject) => {
    let client
    if (!client) {
      try {
        client = await createAuth0Client({
          domain: process.env.REACT_APP_AUTH0_DOMAIN!,
          client_id: process.env.REACT_APP_AUTH0_CLIENT_ID!,
        })
        resolve(client)
      } catch (e) {
        console.log(e)
        reject(new Error('getAuth0Client Error'))
      }
    }
  })
}

export const getTokenSilently = async (...p: any[]) => {
  if (!_client) {
    _client = await getAuth0Client()
  }
  return await _client.getTokenSilently(...p)
}
