export interface NormalizedArrayResponse {
  entities: {
    [key: string]: { [key: number]: any } // TODO Better typing
  }
  result: number[] // TODO better typing?
}

export interface NormalizedEntityResponse {
  entities: {
    [key: string]: { [key: number]: any } // TODO Better typing
  }
  result: number // TODO better typing?
}

/* State/reducer interfaces */
export interface NormalizedStateCollection<T> {
  allIDs: number[]
  byId: { [key: number]: T }
}

export interface FetchPaginatedCallbackParams {
  pageIndex: number
  pageSize: number
  searchQuery?: { [key: string]: string } // TODO: Ransack Object shape
}

export type FetchPaginatedCallback = ({
  pageIndex,
  pageSize,
}: FetchPaginatedCallbackParams) => void

/* UI Components */
export type SidebarItemCollection = (SidebarLink | SidebarSubmenu)[]

export interface SidebarItem {
  superuserOnly?: boolean
  type: string
}

export interface SidebarLink extends SidebarItem {
  id: string
  type: 'link'
  title: string
  to: string
}

export interface SidebarSubmenu extends SidebarItem {
  items: SidebarLink[]
  title: string
  type: 'submenu'
}

/* Entities */
export interface Campaign {
  id: number
  name: string
  active: boolean
  tenant_id: number
  election_id: number
  campaign_type: string
  events: Event[]
  offset_name: string
}

export interface Position {
  name: string
  id: number
  candidacies: Candidacy[]
  description: string
  partisan_type: string
  position_markdowns: PositionMarkdown[]
  level: string
}

export interface Candidacy {
  id: number
  candidate_id: number
  position_id: number
  incumbent: boolean
  the_primary: boolean
  write_in: boolean
  created_at: string
  updated_at: string
  election_id: number
  data_upload_id: number
  row_order: number
  running_mate_candidate_id: number
  candidate_type_id: number
  withdrawn: boolean
  candidate: Candidate
}

export interface Candidate {
  first_name: string
  middle_name: string
  last_name: string
  bio_text: string
  id: number
  email: string
  nickname: string
  headshot: string
  thumb_url: string
  positions: Position[]
  candidate_markdowns: CandidateMarkdown[]
  education: Education[]
  experience: Experience[]
  withdrawn: boolean
  parties: string
  candidacies: Candidacy[]
}

export interface CandidateMarkdown {
  id: number
  tenant_id: number
  candidate_id: number
  markdown_type: string
  created_at: string
  updated_at: string
  markdown_blob: string
  locale: string
}

export interface PositionMarkdown {
  id: number
  tenant_id: number
  position_id: number
  markdown_type: string
  created_at: string
  updated_at: string
  markdown_blob: string
  locale: string
}

export interface Endorsement {
  id: number
  candidacy?: Candidacy
  candidate?: Candidate
  organization: Organization
}

export interface Election {
  id: number
  name: string
  positions: Position[]
  candidacies: Candidacy[]
  election_day: string
  created_at: string
  updated_at: string
  absentee: boolean
  id_requirements_url: string
  voter_registration_url: string
  primary: boolean
  polling_place_url: string | null
  polls_open_at: string | null
  polls_close_at: string | null
  google_civic_info_election_id: string
  absentee_url: string
  address_line_1: string
  address_line_2: string
  city: string
  state: string
  zip: string
  deadline_drop_off: string
  deadline_mail_in: string
  note: string
  caucus: boolean
  deadline_notice: string
  ocd_id: string
  party_id: number | null
  in_person_voting: boolean
  time_notice: string
  default_time_zone: string
  mail_in_must_be_postmarked_by: boolean
  slug: string | null
  election_type_id: null | number
  hidden: boolean
}

export interface Event {
  id: number
  name: string
  offset_time: string
  campaign_id: number
  messages: Message[]
}

export interface Education {
  id: number
  candidate_id: number
  degree: string
  school: string
  major: string
  honors?: string
  grad_year: number
  created_at: string
  updated_at: string
}

export interface Experience {
  id: number
  candidate_id: number
  entry_type: string
  company: string
  position: string
  honors: string
  start_year: string
  end_year: string
  created_at: string
  updated_at: string
  duration: string
}
export interface MessageTranslation {
  id: number
  body: string
  subject: string
  locale: string
  template_id?: number
  message_id: number
}

export interface Message {
  id: number
  event_id: number
  message_translations?: MessageTranslation[]
  published: boolean
  subscriber_type: 'phone' | 'email'
}

export interface Locale {
  name: string
  key: string
}

export interface Measure {
  id: number
  title: string
  name: string
  state: string
}

export type SearchResultType = 'candidates' | 'positions' | 'measures' | null

export interface SearchResults {
  items: any[]
  totalCount: number
  type: SearchResultType
  searchTerm: string
}

export interface SelectOptionType {
  label: string
  value: string
}

export interface Tenant {
  domain: string
  elections: Election[]
  id: number
  name: string
  subdomain: string
  installs: Install[]
  tenant_memberships: TenantMembership[]
}

export interface TenantMembership {
  active: boolean
  id: number
  role: string
  tenant: Tenant
  user: User
  user_id: number
  tenant_id: number
}

export interface User {
  auth0_user_id: string
  election_id: number | null
  email: string
  id: number
  tenant_memberships: TenantMembership[]
}

export interface Install {
  id: string
  name: string
  tenant_id: number
  env: { [key: string]: string }
  style_variables: { [key: string]: string }
  config: { [key: string]: any }
}

export interface Organization {
  id: string
  name: string
}

/* Actions & Action Types */
export const RECEIVE_CANDIDATE = 'RECEIVE_CANDIDATE'
export type RECEIVE_CANDIDATE = typeof RECEIVE_CANDIDATE

export interface ReceiveCandidateAction {
  type: typeof RECEIVE_CANDIDATE
  payload: NormalizedEntityResponse
}

export const RECEIVE_CAMPAIGNS = 'RECEIVE_CAMPAIGNS'
export type RECEIVE_CAMPAIGNS = typeof RECEIVE_CAMPAIGNS
export interface ReceiveCampaignsAction {
  type: typeof RECEIVE_CAMPAIGNS
  payload: NormalizedArrayResponse
}

export const REQUEST_CAMPAIGNS = 'REQUEST_CAMPAIGNS'
export type REQUEST_CAMPAIGNS = typeof REQUEST_CAMPAIGNS
export interface RequestCampaignsAction {
  type: typeof REQUEST_CAMPAIGNS
}

export const RECEIVE_SEARCH_RESULTS = 'RECEIVE_SEARCH_RESULTS'
export type RECEIVE_SEARCH_RESULTS = typeof RECEIVE_SEARCH_RESULTS
export interface ReceiveSearchResultsAction {
  type: typeof RECEIVE_SEARCH_RESULTS
  payload: SearchResults
}

export const REQUEST_SEARCH_RESULTS = 'REQUEST_SEARCH_RESULTS'
export type REQUEST_SEARCH_RESULTS = typeof REQUEST_SEARCH_RESULTS
export interface RequestSearchResultsAction {
  type: typeof REQUEST_SEARCH_RESULTS
}

export const RECEIVE_CAMPAIGN = 'RECEIVE_CAMPAIGN'
export type RECEIVE_CAMPAIGN = typeof RECEIVE_CAMPAIGN

export const REQUEST_CAMPAIGN = 'REQUEST_CAMPAIGN'
export type REQUEST_CAMPAIGN = typeof REQUEST_CAMPAIGN
export interface RequestCampaignAction {
  type: typeof REQUEST_CAMPAIGN
}

export interface ReceiveCampaignAction {
  type: typeof RECEIVE_CAMPAIGN
  payload: NormalizedEntityResponse
}

export const CREATE_CAMPAIGN_ERROR = 'CREATE_CAMPAIGN_ERROR'
export type CREATE_CAMPAIGN_ERROR = typeof CREATE_CAMPAIGN_ERROR
export interface CreateCampaignErrorAction {
  type: typeof CREATE_CAMPAIGN_ERROR
  payload: Response
}

export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS'
export type CREATE_CAMPAIGN_SUCCESS = typeof CREATE_CAMPAIGN_SUCCESS
export interface CreateCampaignSuccessAction {
  type: typeof CREATE_CAMPAIGN_SUCCESS
  payload: NormalizedEntityResponse
}

export const CREATING_CAMPAIGN = 'CREATING_CAMPAIGN'
export type CREATING_CAMPAIGN = typeof CREATING_CAMPAIGN
export interface CreatingCampaignAction {
  type: typeof CREATING_CAMPAIGN
}

export const CREATING_EVENT = 'CREATING_EVENT'
export type CREATING_EVENT = typeof CREATING_EVENT
export interface CreatingEventAction {
  type: typeof CREATING_EVENT
}

export const RECEIVE_EVENT = 'RECEIVE_EVENT'
export type RECEIVE_EVENT = typeof RECEIVE_EVENT

export const REQUEST_EVENT = 'REQUEST_EVENT'
export type REQUEST_EVENT = typeof REQUEST_EVENT

export const CREATE_EVENT_ERROR = 'CREATE_EVENT_ERROR'
export type CREATE_EVENT_ERROR = typeof CREATE_EVENT_ERROR
export interface CreateEventErrorAction {
  type: typeof CREATE_EVENT_ERROR
  payload: Response
}

export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS'
export type CREATE_EVENT_SUCCESS = typeof CREATE_EVENT_SUCCESS
export interface CreateEventSuccessAction {
  type: typeof CREATE_EVENT_SUCCESS
  payload: NormalizedEntityResponse
}

export const CREATING_CANDIDATE_MARKDOWN = 'CREATING_CANDIDATE_MARKDOWN'
export type CREATING_CANDIDATE_MARKDOWN = typeof CREATING_CANDIDATE_MARKDOWN
export interface CreatingCandidateMarkdownAction {
  type: typeof CREATING_CANDIDATE_MARKDOWN
}

export const CREATE_CANDIDATE_MARKDOWN_SUCCESS =
  'CREATE_CANDIDATE_MARKDOWN_SUCCESS'
export type CREATE_CANDIDATE_MARKDOWN_SUCCESS = typeof CREATE_CANDIDATE_MARKDOWN_SUCCESS
export interface CreateCandidateMarkdownSuccessAction {
  type: typeof CREATE_CANDIDATE_MARKDOWN_SUCCESS
  payload: NormalizedEntityResponse
}

export const CREATE_CANDIDATE_MARKDOWN_ERROR = 'CREATE_CANDIDATE_MARKDOWN_ERROR'
export type CREATE_CANDIDATE_MARKDOWN_ERROR = typeof CREATE_CANDIDATE_MARKDOWN_ERROR
export interface CreateCandidateMarkdownErrorAction {
  type: typeof CREATE_CANDIDATE_MARKDOWN_ERROR
  payload: Response
}

export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM'
export type UPDATE_SEARCH_TERM = typeof UPDATE_SEARCH_TERM
export interface UpdateSearchTermAction {
  type: typeof UPDATE_SEARCH_TERM
  payload: Response
}

export const CREATE_MESSAGE_TRANSLATION_ERROR =
  'CREATE_MESSAGE_TRANSLATION_ERROR'
export type CREATE_MESSAGE_TRANSLATION_ERROR = typeof CREATE_MESSAGE_TRANSLATION_ERROR

export const UPDATE_MESSAGE_TRANSLATION_ERROR =
  'UPDATE_MESSAGE_TRANSLATION_ERROR'
export type UPDATE_MESSAGE_TRANSLATION_ERROR = typeof UPDATE_MESSAGE_TRANSLATION_ERROR

/* API fetch action types */
export const FETCH = 'FETCH'
export type FETCH = typeof FETCH
export interface FetchAction {
  type: typeof FETCH
  path: string
  options: Record<string, any>
}

export const FETCH_ERROR = 'FETCH_ERROR'
export type FETCH_ERROR = typeof FETCH_ERROR
export interface FetchErrorAction {
  type: typeof FETCH_ERROR
  status: number
  statusText: string
}

export const FETCH_RECEIVE = 'FETCH_RECEIVE'
export type FETCH_RECEIVE = typeof FETCH_RECEIVE
export interface FetchReceiveAction {
  type: typeof FETCH_RECEIVE
}

export const FETCH_REQUEST = 'FETCH_REQUEST'
export type FETCH_REQUEST = typeof FETCH_REQUEST
export interface FetchRequestAction {
  type: typeof FETCH_REQUEST
}

export const DEFAULT_PAGE_SIZE = 20
