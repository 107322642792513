import React from 'react'

interface Props {
  emoji: string
  label: string
}

const Emoji: React.FC<Props> = ({ emoji, label }) => (
  <span
    role="img"
    aria-label={label ? label : ''}
    aria-hidden={label ? 'false' : 'true'}
  >
    {emoji}
  </span>
)

export default Emoji
