import { createSlice } from '@reduxjs/toolkit'
import messagesAdapter from 'adapters/messagesAdapter'

export default createSlice({
  name: 'messages',
  initialState: messagesAdapter.getInitialState(),
  reducers: {
    messagesReceived(state, action) {
      messagesAdapter.addMany(state, action.payload.messages)
    },
  },
  extraReducers: {
    'campaigns/campaignsReceived': (state, action) => {
      messagesAdapter.addMany(state, action.payload.messages || [])
    },
    'messageTranslations/messageTranslationDeleted': (state, action) => {
      const messageId = action.payload.messageId
      const newMessageTranslationList = state.entities[
        messageId
      ]?.message_translations?.filter((translation) => {
        return translation !== action.payload.id
      })
      messagesAdapter.updateOne(state, {
        id: messageId,
        changes: { message_translations: newMessageTranslationList },
      })
    },
    'messageTranslations/messageTranslationCreated': (state, action) => {
      const messageId = action.payload.messageTranslations[0].message_id
      const newTranslations = state.entities[
        messageId
      ]?.message_translations?.concat([
        action.payload.messageTranslations[0].id,
      ])

      messagesAdapter.updateOne(state, {
        id: messageId,
        changes: {
          message_translations: newTranslations,
        },
      })
    },
  },
})
