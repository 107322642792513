import { createSlice } from '@reduxjs/toolkit'
import endorsementsAdapter from '../adapters/endorsementsAdapter'

export default createSlice({
  name: 'endorsements',
  initialState: endorsementsAdapter.getInitialState({
    totalCount: 0,
  }),
  reducers: {
    endorsementsReceived(state, action) {
      endorsementsAdapter.addMany(state, action.payload.endorsements)
      state.totalCount =
        action.payload.totalCount && action.payload.totalCount[0]
    },
  },
})
