import { createSlice } from '@reduxjs/toolkit'
import positionsAdapter from '../adapters/positionsAdapter'
import candidaciesSlice from './candidaciesSlice'
import { Candidacy } from '../types'
import { positionMarkdownsSlice } from './positionMarkdownsSlice'
import endorsementsSlice from './endorsementsSlice'

export default createSlice({
  name: 'positions',
  initialState: positionsAdapter.getInitialState({ totalCount: 0 }),
  reducers: {
    positionsReceived(state, action) {
      positionsAdapter.addMany(state, action.payload.positions || [])
      state.totalCount =
        action.payload.totalCount && action.payload.totalCount[0]
    },
  },
  extraReducers: {
    [candidaciesSlice.actions.candidaciesReceived.toString()]: (
      state,
      action,
    ) => {
      const positionIds: number[] = Array.from(
        new Set(
          action.payload.candidacies.map((c: Candidacy) => c.position_id),
        ),
      )

      positionIds.forEach((id: number) => {
        positionsAdapter.updateOne(state, {
          id,
          changes: {
            candidacies: action.payload.candidacies
              .filter((candidacy: Candidacy) => candidacy.position_id === id)
              .map((c: Candidacy) => c.id),
          },
        })
      })
    },
    [positionMarkdownsSlice.actions.positionMarkdownDeleted.toString()]: (
      state,
      action,
    ) => {
      const positionId = action.payload.position_id
      // Remove the id from the array
      const newMarkdownList = state.entities[
        positionId
      ]?.position_markdowns.filter((item) => item !== action.payload.id)
      positionsAdapter.updateOne(state, {
        id: positionId,
        changes: { position_markdowns: newMarkdownList },
      })
    },
    [positionMarkdownsSlice.actions.positionMarkdownCreated.toString()]: (
      state,
      action,
    ) => {
      const positionId = action.payload.position_id
      const newMarkdownList = state.entities[
        positionId
      ]?.position_markdowns.concat(action.payload.id)
      positionsAdapter.updateOne(state, {
        id: positionId,
        changes: { position_markdowns: newMarkdownList },
      })
    },
    [endorsementsSlice.actions.endorsementsReceived.toString()]: (
      state,
      action,
    ) => {
      positionsAdapter.addMany(state, action.payload.positions || [])
    },
  },
})
