import { createAsyncThunk } from '@reduxjs/toolkit'
import apiFetch from './apiFetch'

export default createAsyncThunk(
  'currentUser/create',
  async (_, { dispatch, rejectWithValue, signal }) => {
    const response = ((await dispatch(
      apiFetch('/user', {
        method: 'POST',
        signal,
      }),
    )) as unknown) as Response

    const json = await response.json()

    if (!response.ok) {
      return rejectWithValue(json)
    }

    return json
  },
)
