import { createSlice } from '@reduxjs/toolkit'
import eventsAdapter from 'adapters/eventsAdapter'

export default createSlice({
  name: 'events',
  initialState: eventsAdapter.getInitialState(),
  reducers: {
    eventsReceived(state, action) {
      eventsAdapter.addMany(state, action.payload.events)
    },
  },
  extraReducers: {
    'campaigns/campaignsReceived': (state, action) => {
      eventsAdapter.addMany(state, action.payload.events || [])
    },
  },
})
