import React from 'react'
import Spinner from './Spinner/Spinner'
import styles from './MessageScene.module.scss'

interface Props {
  spinner?: boolean
}

const MessageScene: React.FC<Props> = ({ children, spinner }) => (
  <div className={styles.scene}>
    {spinner && <Spinner size="lg" />}
    {children && <div className={styles.message}>{children}</div>}
  </div>
)

export default MessageScene
