import { createSlice } from '@reduxjs/toolkit'
import installsAdapter from 'adapters/installsAdapter'

export default createSlice({
  name: 'installs',
  initialState: installsAdapter.getInitialState({
    totalCount: 0,
  }),
  reducers: {
    installsReceived(state, action) {
      installsAdapter.addMany(state, action.payload.installs)
      state.totalCount = action.payload.totalCount
    },
    installUpdated(state, action) {
      installsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload },
      })
    },
  },
})
