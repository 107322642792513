import { useEffect } from 'react'
import setActiveNavItemId from '../actions/setActiveNavItemId'
import { useDispatch } from 'react-redux'

export default (id: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setActiveNavItemId(id))
  }, [dispatch, id])
}
