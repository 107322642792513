import { createSlice } from '@reduxjs/toolkit'
import candidatesAdapter from 'adapters/candidatesAdapter'
import candidaciesSlice from './candidaciesSlice'
import { candidateMarkdownsSlice } from './candidateMarkdownsSlice'
import endorsementsSlice from './endorsementsSlice'

export default createSlice({
  name: 'candidates',
  initialState: candidatesAdapter.getInitialState(),
  reducers: {
    candidatesReceived(state, action) {
      candidatesAdapter.upsertMany(state, action.payload.candidates)
    },
  },
  extraReducers: {
    [candidaciesSlice.actions.candidaciesReceived.toString()]: (
      state,
      action,
    ) => {
      candidatesAdapter.addMany(state, action.payload.candidates || [])
    },
    [candidateMarkdownsSlice.actions.candidateMarkdownCreated.toString()]: (
      state,
      action,
    ) => {
      const candidateId = action.payload.candidate_id
      const newMarkdownList = state.entities[
        candidateId
      ]?.candidate_markdowns.concat(action.payload.id)
      candidatesAdapter.updateOne(state, {
        id: candidateId,
        changes: { candidate_markdowns: newMarkdownList },
      })
    },
    [candidateMarkdownsSlice.actions.candidateMarkdownDeleted.toString()]: (
      state,
      action,
    ) => {
      const candidateId = action.payload.candidate_id
      // Remove the id from the array
      const newMarkdownList = state.entities[
        candidateId
      ]?.candidate_markdowns.filter((item) => item !== action.payload.id)
      candidatesAdapter.updateOne(state, {
        id: candidateId,
        changes: { candidate_markdowns: newMarkdownList },
      })
    },
    [endorsementsSlice.actions.endorsementsReceived.toString()]: (
      state,
      action,
    ) => {
      candidatesAdapter.addMany(state, action.payload.candidates || [])
    },
  },
})
