import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select, { ValueType } from 'react-select'
import { RootState } from 'rootReducer'
import updateTenantMembership from 'actions/updateTenantMembership'
import { User } from 'types'
import useAuth0 from 'hooks/useAuth0'

type OptionType = {
  label: string
  value: number
}

const TenantSwitcher = () => {
  const dispatch = useDispatch()
  const { loginWithRedirect } = useAuth0()
  const currentUser = useSelector<RootState, User | null>(
    ({ currentUser }) => currentUser,
  )

  if (!currentUser || currentUser.tenant_memberships.length <= 1) {
    return null
  }

  const handleChange = async (selectedOption: ValueType<OptionType>) => {
    const membershipId = (selectedOption as OptionType).value

    await dispatch(
      updateTenantMembership({
        id: membershipId,
        values: { active: true },
      }),
    )

    // Redirect so Auth0 user is refreshed
    loginWithRedirect()
  }

  const activeMembership = currentUser.tenant_memberships.find((m) => m.active)

  const options: OptionType[] = currentUser.tenant_memberships
    .map((membership) => ({
      label: membership.tenant.name,
      value: membership.id,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))

  const selectedOption = options.find((o) => o.value === activeMembership?.id)

  return (
    <Select
      menuPlacement="top"
      onChange={handleChange}
      options={options}
      value={selectedOption}
    />
  )
}

export default TenantSwitcher
