import { createAsyncThunk } from '@reduxjs/toolkit'
import apiFetch from './apiFetch'
import { TenantMembership } from '../types'

export default createAsyncThunk(
  'currentUser/update',
  async (
    params: { id: number; values: Partial<TenantMembership> },
    { dispatch, rejectWithValue, signal },
  ) => {
    const response = ((await dispatch(
      apiFetch(`/tenant_memberships/${params.id}`, {
        method: 'PATCH',
        body: JSON.stringify(params.values),
        signal,
      }),
    )) as unknown) as Response

    const json = await response.json()

    if (!response.ok) {
      return rejectWithValue(json)
    }

    return json
  },
)
