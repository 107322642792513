import { FETCH, FetchAction } from '../types'
import {
  apiFetchError,
  apiFetchReceive,
  apiFetchRequest,
} from '../actions/apiFetch'
import { getTokenSilently } from '../react-auth0-spa'
import { Action, Middleware, AnyAction } from 'redux'

type PromiseDispatch = <T extends Action>(response: Promise<T>) => Promise<T>

export default (): Middleware<PromiseDispatch> => (store) => (next) => (
  action: AnyAction,
): AnyAction | Promise<Response> => {
  const baseUrl = process.env.REACT_APP_DASHBOARD_API_BASE_URL

  if (!baseUrl) {
    throw new Error('REACT_APP_DASHBOARD_API_BASE_URL must be set')
  }

  if (!(action instanceof Promise) && action?.type === FETCH) {
    store.dispatch(apiFetchRequest())

    return (async () => {
      const { path, options } = action as FetchAction
      const cleanBaseUrl = baseUrl.replace(/\/$/, '') // Strip trailing slash
      const cleanPath = path.replace(/^\//, '') // Strip leading slash
      const audienceOptions = {
        audience: process.env.REACT_APP_DASHBOARD_API_AUDIENCE,
        scope: '', // TODO Use proper scope?
      }

      const token = await getTokenSilently(audienceOptions)

      const optionsWithDefaults = Object.assign({}, options, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })

      const response = await fetch(
        `${cleanBaseUrl}/${cleanPath}`,
        optionsWithDefaults,
      )

      if (!response.ok) {
        store.dispatch(apiFetchError(response))
      }

      // Why are we using a timeout here?
      //
      // The apiFetchRequest and apiFetchReceive actions help us keep track of
      // currently running requests (see fetchCounterReducer). This is a great
      // way to show a loading spinner while requests are happening. However,
      // the loading spinner will flash on and off when doing subsequent
      // requests if we don't 'smooth out' the receiving/requesting. We need the
      // previous RECEIVE to dispatch only after the next REQUEST has been
      // dispatched.
      setTimeout(() => store.dispatch(apiFetchReceive()), 100)

      return response
    })()
  }

  return next(action)
}
