import SelectField from 'components/TopNav/SearchForm/SelectField/SelectField'
import Button from 'components/TopNav/UserMenu/Button/Button'
import { Field, Form, Formik, FormikValues } from 'formik'
import React, { CSSProperties } from 'react'
import { Election, SelectOptionType } from 'types'
import styles from './SearchFilters.module.scss'
import { states } from 'utils/usStates'
import { levels } from 'utils/levels'
import { Styles } from 'react-select'
import classNames from 'classnames'

export interface Props {
  nameInput?: boolean
  stateFilter?: boolean
  levelFilter?: boolean
  nameInputLabel?: string
  countyInput?: boolean
  initialValues: { [key: string]: string }
  updateData: (values: { [key: string]: string }) => void
}

const customStyles: Partial<Styles> = {
  indicatorSeparator: () => ({}),
  indicatorsContainer: (provided: CSSProperties) => ({
    ...provided,
    paddingRight: '1rem',
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    border: '1px solid #EAEAEA',
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    border: '1px solid #EAEAEA',
    boxShadow: '0 2px 4px 0 rgba(177,177,177,0.5)',
    marginTop: '-1px',
  }),
}

const SearchFilters: React.FC<Props> = ({
  updateData,
  nameInput,
  stateFilter,
  levelFilter,
  nameInputLabel,
  countyInput,
  initialValues,
}) => {
  const handleSubmit = (values: FormikValues) => {
    updateData(values)
  }
  const levelOptions: SelectOptionType[] = levels.map((level) => ({
    label: level,
    value: level,
  }))

  const stateOptions: SelectOptionType[] = states.map((state) => ({
    label: state.name,
    value: state.short_name,
  }))

  return (
    <div className={styles.container}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue, values, handleChange, handleBlur }) => {
          return (
            <Form className={styles.filterForm}>
              {levelFilter && (
                <div className={styles.filterContainer}>
                  <label className={styles.label}>Level</label>
                  <Field
                    className={styles.field}
                    component={SelectField}
                    name="level"
                    value={values.level}
                    options={levelOptions}
                    customStyles={customStyles}
                    isClearable={true}
                  />
                </div>
              )}

              {stateFilter && (
                <div className={styles.filterContainer}>
                  <label className={styles.label}>State</label>
                  <Field
                    className={styles.field}
                    component={SelectField}
                    name="state"
                    value={values.state}
                    options={stateOptions}
                    customStyles={customStyles}
                    isClearable={true}
                  />
                </div>
              )}
              {nameInput && (
                <div className={styles.filterContainer}>
                  <label className={styles.label}>{nameInputLabel}</label>
                  <Field
                    name="name"
                    value={values.name}
                    className={classNames(styles.field, styles.nameInput)}
                    placeholder={'search for "Governor"'}
                  />
                </div>
              )}
              {countyInput && (
                <div className={styles.filterContainer}>
                  <label className={styles.label}>County</label>
                  <Field
                    name="county"
                    value={values.county}
                    className={classNames(styles.field, styles.nameInput)}
                  />
                </div>
              )}
              <Button type="submit" className={styles.applyButton}>
                Apply
              </Button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default SearchFilters
