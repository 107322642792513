import {
  RECEIVE_SEARCH_RESULTS,
  ReceiveSearchResultsAction,
  RequestSearchResultsAction,
  SearchResults,
} from '../types'

const initialState: SearchResults = {
  items: [],
  totalCount: 0,
  type: null,
  searchTerm: '',
}

export default (
  state = initialState,
  action: ReceiveSearchResultsAction | RequestSearchResultsAction,
) => {
  switch (action.type) {
    case RECEIVE_SEARCH_RESULTS:
      return action.payload
    default:
      return state
  }
}
