import apiFetch from './apiFetch'
import { AppThunk } from '../store'
import electionsSlice from '../slices/electionsSlice'
import { Election } from '../types'
import customNormalize from 'utils/customNormalize'
import { electionListSchema } from 'schema'

// TODO Add generic case without tenant id?
export default (): AppThunk => async (dispatch) => {
  const response = ((await dispatch(
    apiFetch(`/elections`),
  )) as unknown) as Response

  if (!response.ok) {
    return
  }

  const json = (await response.json()) as Election[]
  const normalized = customNormalize(json, electionListSchema)

  return dispatch(electionsSlice.actions.electionsReceived(normalized))
}
