import { PositionMarkdown } from 'types'
import apiFetch from './apiFetch'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { positionMarkdownsSlice } from 'slices/positionMarkdownsSlice'

export default createAsyncThunk(
  'positionMarkdowns/positionMarkdownUpdated',
  async (
    params: { id: number; values: Partial<PositionMarkdown> },
    { dispatch, rejectWithValue, signal },
  ) => {
    const response = ((await dispatch(
      apiFetch(`/position_markdowns/${params.id}`, {
        method: 'PATCH',
        body: JSON.stringify(params.values),
        signal,
      }),
    )) as unknown) as Response

    const json = await response.json()

    if (!response.ok) {
      return rejectWithValue(json)
    }
    const { positionMarkdownUpdated } = positionMarkdownsSlice.actions
    dispatch(positionMarkdownUpdated(json))
    return json
  },
)
