import { faPencil } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useState } from 'react'
import { Position, PositionMarkdown } from 'types'
import EditPositionRow from './EditPositionRow/EditPositionRow'
import styles from './PositionRow.module.scss'
import positionRowContainer from './positionRowContainer'

export interface Props {
  position: Position
  handleCreatePositionMarkdown: (
    values: any,
    positionId: number,
    markdownType: 'name' | 'description',
  ) => void
  handleUpdatePositionMarkdown: (
    id: number,
    values: any,
    positionId: number,
    markdownType: 'name' | 'description',
  ) => void
  handleDeletePositionMarkdown: (id: number, positionId: number) => void
}

const PositionRow: React.FC<Props> = ({
  position,
  handleCreatePositionMarkdown,
  handleUpdatePositionMarkdown,
  handleDeletePositionMarkdown,
}) => {
  let [editing, setEditing] = useState<Boolean>(false)
  let [markdownLocale, setMarkdownLocale] = useState<string>('en')

  const nameMarkdown =
    position.position_markdowns.find(
      (markdown) =>
        markdown.markdown_type === 'name' && markdown.locale === markdownLocale,
    ) || null
  const descriptionMarkdown =
    position.position_markdowns.find(
      (markdown) =>
        markdown.markdown_type === 'description' &&
        markdown.locale === markdownLocale,
    ) || null

  const displayName = position.position_markdowns.find(
    (markdown) => markdown.markdown_type === 'name' && markdown.locale === 'en',
  )?.markdown_blob
  
  const updateLocale = (locale: string) => {
    setMarkdownLocale(locale)
  }

  const determineMarkdownAction = async (
    markdownObject: PositionMarkdown | null,
    newValue: string,
    markdownType: 'name' | 'description',
    values: any,
  ) => {
    if (newValue !== '') {
      if (markdownObject === null) {
        //create new
        await handleCreatePositionMarkdown(values, position.id, markdownType)
      } else if (markdownObject.markdown_blob !== newValue) {
        //update
        await handleUpdatePositionMarkdown(
          markdownObject.id,
          values,
          position.id,
          markdownType,
        )
      }
    } else if (newValue === '' && markdownObject !== null) {
      await handleDeletePositionMarkdown(markdownObject.id, position.id)
    }
  }

  const handleSubmit = async (values: any) => {
    await determineMarkdownAction(
      nameMarkdown,
      values.customName,
      'name',
      values,
    )
    await determineMarkdownAction(
      descriptionMarkdown,
      values.customDescription,
      'description',
      values,
    )
    setEditing(false)
  }

  if (editing) {
    return (
      <EditPositionRow
        position={position}
        customName={nameMarkdown ? nameMarkdown.markdown_blob : null}
        customDescription={
          descriptionMarkdown ? descriptionMarkdown.markdown_blob : null
        }
        setEditing={setEditing}
        handleSubmit={handleSubmit}
        updateLocale={updateLocale}
        locale={markdownLocale}
      />
    )
  } else {
    return (
      <tr className={styles.positionRow}>
        <td className={classNames(styles.positionName, styles.positionItem)}>
          <div>{position.name}</div>
          {displayName && (
            <div className={styles.nameMarkdown}>
              Display name: {displayName}
            </div>
          )}
        </td>
        <td
          className={classNames(
            styles.positionItem,
            styles.positionDescription,
          )}
        >
          {position.description}
        </td>
        <button
          onClick={() => setEditing(true)}
          className={classNames(styles.positionItem, styles.editIcon)}
        >
          <FontAwesomeIcon icon={faPencil} />
        </button>
      </tr>
    )
  }
}

export default positionRowContainer(PositionRow)
