import { combineReducers } from 'redux'
import activeNavItemId from './reducers/activeNavItemReducer'
import currentUser from './reducers/currentUserReducer'
import activeElectionId from './reducers/activeElectionIdReducer'
import entities from './reducers/entitiesReducer'
import searchResults from './reducers/searchResultsReducer'

export const rootReducer = combineReducers({
  activeNavItemId,
  currentUser,
  activeElectionId,
  entities,
  searchResults,
})

export type RootState = ReturnType<typeof rootReducer>
