import {
  RECEIVE_SEARCH_RESULTS,
  REQUEST_SEARCH_RESULTS,
  SearchResults,
  SearchResultType,
} from '../types'
import apiFetch from './apiFetch'
import { AppThunk } from '../store'
import qs from 'qs'

const receiveSearchResults = (payload: SearchResults) => ({
  type: RECEIVE_SEARCH_RESULTS,
  payload,
})

const requestSearchResults = () => ({ type: REQUEST_SEARCH_RESULTS })

interface SearchProps {
  searchTerm: string
  type: SearchResultType
  pageIndex: number
  pageSize: number
  history?: any
  level?: string
  state?: string
  county?: string
}

export default ({
  searchTerm,
  type,
  pageIndex,
  pageSize,
  history,
  level,
  state,
  county,
}: SearchProps): AppThunk => async (dispatch) => {
  dispatch(requestSearchResults())
  let params
  switch (type) {
    case 'positions':
      params = qs.stringify({
        page: (pageIndex as number) + 1,
        page_size: pageSize,
        'q[name_cont]': searchTerm,
        'q[state_eq]': state,
        'q[level_eq]': level,
        'q[counties_includes]': county,
      })
      break
    case 'candidates':
      params = qs.stringify({
        page: (pageIndex as number) + 1,
        page_size: pageSize,
        'q[full_name_cont]': searchTerm,
        'q[positions_state_eq]': state,
        'q[positions_level_eq]': level,
      })
      break
    default:
      params = qs.stringify({
        page: (pageIndex as number) + 1,
        page_size: pageSize,
        'q[name_cont]': searchTerm,
      })
  }

  const response = ((await dispatch(
    apiFetch(`/${type}/search?${params}`, {
      method: 'POST',
    }),
  )) as unknown) as Response

  if (!response.ok) {
    return
  }

  const totalCount = response.headers.get('X-Total')
  const items = await response.json()
  if (items && totalCount) {
    if (history) {
      history.push('/search')
    }
    return dispatch(
      receiveSearchResults({
        items: items,
        totalCount: parseInt(totalCount),
        type,
        searchTerm,
      }),
    )
  }
}
