import React, { useEffect, useState } from 'react'
import styles from './searchResultsScene.module.scss'
import {
  SearchResults,
  Position,
  DEFAULT_PAGE_SIZE,
  Candidate,
} from '../../../types'
import searchResultsSceneContainer from './searchResultsSceneContainer'
import MessageScene from 'components/MessageScene/MessageScene'
import Emoji from 'components/Emoji/Emoji'
import PositionRow from 'components/DataReviewApp/PositionsScene/PositionRow/PositionRow'
import Pagination from 'components/DataReviewApp/PositionsScene/Pagination/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import fetchSearchResults from 'actions/fetchSearchResults'
import { RootState } from 'rootReducer'
import SearchFilters from 'components/DataReviewApp/PositionsScene/SearchFilters/SearchFilters'
import CandidateRow from './CandidateRow/CandidateRow'
import fetchPositions from 'actions/fetchPositions'

interface Props {
  searchResults: SearchResults
}

const SearchResultsScene: React.FC<Props> = ({ searchResults }) => {
  const dispatch = useDispatch()
  let { searchTerm, searchType, electionId, positions } = useSelector(
    ({
      searchResults: { searchTerm, type },
      currentUser,
      entities: { positions },
    }: RootState) => ({
      searchTerm,
      searchType: type,
      electionId: currentUser?.election_id,
      positions: positions.entities,
    }),
  )
  let [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
  let [stateFilter, setStateFilter] = useState<string>('')
  let [levelFilter, setLevelFilter] = useState<string>('')
  let [countyFilter, setCountyFilter] = useState<string>('')
  let [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const getResults = async () => {
      setIsLoading(true)
      await dispatch(
        fetchSearchResults({
          searchTerm,
          type: searchType,
          pageIndex: currentPageIndex,
          pageSize: DEFAULT_PAGE_SIZE,
          state: stateFilter,
          level: levelFilter,
          county: countyFilter,
        }),
      )
      setIsLoading(false)
    }
    getResults()
  }, [
    countyFilter,
    currentPageIndex,
    dispatch,
    levelFilter,
    searchTerm,
    searchType,
    stateFilter,
  ])

  useEffect(() => {
    const getPositions = async () => {
      if (searchType === 'candidates' && electionId) {
        setIsLoading(true)
        const positionIds = searchResults.items
          .map((candidate) => {
            return candidate.candidacies[0].position_id
          })
          .join(',')
        await dispatch(
          fetchPositions({
            electionId,
            pageIndex: currentPageIndex,
            pageSize: DEFAULT_PAGE_SIZE,
            id: positionIds,
          }),
        )
        setIsLoading(false)
      }
    }
    getPositions()
  }, [currentPageIndex, dispatch, electionId, searchResults.items, searchType])

  const paginate = (pageIndex: number) => {
    setCurrentPageIndex(pageIndex)
  }

  //positions
  const updatePositions = (values: { [key: string]: string }) => {
    setLevelFilter(values.level)
    setStateFilter(values.state)
    setCountyFilter(values.county)

    setCurrentPageIndex(0) // always go to first page when new filters
  }

  const positionInitialValues = {
    state: stateFilter,
    level: levelFilter,
    county: countyFilter,
  }

  //candidates
  const candidateInitialValues = {
    state: stateFilter,
    level: levelFilter,
  }
  const updateCandidates = (values: { [key: string]: string }) => {
    setStateFilter(values.state)
    setLevelFilter(values.level)
    setCurrentPageIndex(0) // always go to first page when new filters
  }

  if (isLoading) {
    return (
      <MessageScene>
        <h2>
          Loading... <Emoji emoji="⏳" label="waiting" />
        </h2>
      </MessageScene>
    )
  }

  return (
    <div>
      <h2>
        Search Results for "{searchTerm}" in {searchType}
      </h2>

      {searchType === 'positions' && (
        <>
          <SearchFilters
            initialValues={positionInitialValues}
            stateFilter
            levelFilter
            countyInput
            updateData={updatePositions}
          />
          {searchResults.items.map((p: Position) => {
            return <PositionRow position={p} key={p.id} />
          })}
        </>
      )}
      {searchType === 'candidates' && (
        <>
          <SearchFilters
            initialValues={candidateInitialValues}
            stateFilter
            levelFilter
            updateData={updateCandidates}
          />
          {searchResults.items.map((c: Candidate, i) => {
            return (
              <CandidateRow
                candidate={c}
                key={c.id}
                position={positions[c.candidacies[0].position_id]}
              />
            )
          })}
        </>
      )}

      <Pagination
        currentPageIndex={currentPageIndex}
        paginate={paginate}
        totalRows={searchResults.totalCount}
        rowsPerPage={DEFAULT_PAGE_SIZE}
      />
      {searchResults.totalCount === 0 && (
        <MessageScene>
          <h1>
            No results found <Emoji emoji="😵" label="unamused" />
          </h1>
        </MessageScene>
      )}
    </div>
  )
}

export default searchResultsSceneContainer(SearchResultsScene)
