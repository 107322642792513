import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { SidebarLink as ISidebarLink } from '../../../types'
import styles from './Sub.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../rootReducer'

interface Props {
  currentPath: string
  items: ISidebarLink[]
  title: string
}

const Sub: React.FC<Props> = ({ currentPath, items, title }) => {
  const activeNavItemId = useSelector(
    ({ activeNavItemId }: RootState) => activeNavItemId,
  )
  const [isOpen, setIsOpen] = useState(false)

  const active = items.findIndex(i => i.id === activeNavItemId) !== -1

  useEffect(() => {
    if (active) {
      setIsOpen(true)
    }
  }, [active])

  return (
    <div
      className={classNames(styles.root, {
        [styles.active]: active,
        [styles.open]: isOpen,
      })}
    >
      <div
        className={classNames(styles.title)}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} />
      </div>
      {isOpen && (
        <nav className={styles.items}>
          {items.map((item, i) => (
            <Link
              className={classNames(styles.link, {
                [styles.activeLink]: item.id === activeNavItemId,
              })}
              key={i}
              to={item.to}
            >
              {item.title}
            </Link>
          ))}
        </nav>
      )}
    </div>
  )
}

export default Sub
