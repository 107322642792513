import React, { useState, useRef } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import styles from './TopNavMenu.module.scss'
import useOutsideClick from 'hooks/useOutsideClick'

interface Props {
  buttonChildren(): React.ReactNode
  className?: string
}

const TopNavMenu: React.FC<Props> = ({
  buttonChildren,
  children,
  className,
}) => {
  const node = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  useOutsideClick(node, () => setIsOpen(false))

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.open]: isOpen,
      })}
      ref={node}
    >
      <div className={styles.button} onClick={() => setIsOpen(!isOpen)}>
        {buttonChildren()}
        <FontAwesomeIcon className={styles.chevron} icon={faChevronDown} />
      </div>
      {isOpen && <div className={styles.menu}>{children}</div>}
    </div>
  )
}

export default TopNavMenu
