import { createSlice } from '@reduxjs/toolkit'
import messageTranslationsAdapter from 'adapters/messageTranslationsAdapter'

export default createSlice({
  name: 'messageTranslations',
  initialState: messageTranslationsAdapter.getInitialState(),
  reducers: {
    messageTranslationUpdated(state, action) {
      messageTranslationsAdapter.upsertMany(
        state,
        action.payload.messageTranslations,
      )
    },
    messageTranslationDeleted(state, action) {
      messageTranslationsAdapter.removeOne(state, action.payload.id)
    },
    messageTranslationCreated(state, action) {
      messageTranslationsAdapter.addOne(
        state,
        action.payload.messageTranslations[0],
      )
    },
  },
  extraReducers: {
    'campaigns/campaignsReceived': (state, action) => {
      messageTranslationsAdapter.addMany(
        state,
        action.payload.messageTranslations || [],
      )
    },
  },
})
