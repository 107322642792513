export const states = [
  {
    name: 'Alabama',
    short_name: 'AL',
  },
  {
    name: 'Alaska',
    short_name: 'AK',
  },
  {
    name: 'Arizona',
    short_name: 'AZ',
  },
  {
    name: 'Arkansas',
    short_name: 'AR',
  },
  {
    name: 'California',
    short_name: 'CA',
  },
  {
    name: 'Colorado',
    short_name: 'CO',
  },
  {
    name: 'Connecticut',
    short_name: 'CT',
  },
  {
    name: 'Delaware',
    short_name: 'DE',
  },
  {
    name: 'Florida',
    short_name: 'FL',
  },
  {
    name: 'Georgia',
    short_name: 'GA',
  },
  {
    name: 'Hawaii',
    short_name: 'HI',
  },
  {
    name: 'Idaho',
    short_name: 'ID',
  },
  {
    name: 'Illinois',
    short_name: 'IL',
  },
  {
    name: 'Indiana',
    short_name: 'IN',
  },
  {
    name: 'Iowa',
    short_name: 'IA',
  },
  {
    name: 'Kansas',
    short_name: 'KS',
  },
  {
    name: 'Kentucky',
    short_name: 'KY',
  },
  {
    name: 'Louisiana',
    short_name: 'LA',
  },
  {
    name: 'Maine',
    short_name: 'ME',
  },
  {
    name: 'Maryland',
    short_name: 'MD',
  },
  {
    name: 'Massachusetts',
    short_name: 'MA',
  },
  {
    name: 'Michigan',
    short_name: 'MI',
  },
  {
    name: 'Minnesota',
    short_name: 'MN',
  },
  {
    name: 'Mississippi',
    short_name: 'MS',
  },
  {
    name: 'Missouri',
    short_name: 'MO',
  },
  {
    name: 'Montana',
    short_name: 'MT',
  },
  {
    name: 'Nebraska',
    short_name: 'NE',
  },
  {
    name: 'Nevada',
    short_name: 'NV',
  },
  {
    name: 'New Hampshire',
    short_name: 'NH',
  },
  {
    name: 'New Jersey',
    short_name: 'NJ',
  },
  {
    name: 'New Mexico',
    short_name: 'NM',
  },
  {
    name: 'New York',
    short_name: 'NY',
  },
  {
    name: 'North Carolina',
    short_name: 'NC',
  },
  {
    name: 'North Dakota',
    short_name: 'ND',
  },
  {
    name: 'Ohio',
    short_name: 'OH',
  },
  {
    name: 'Oklahoma',
    short_name: 'OK',
  },
  {
    name: 'Oregon',
    short_name: 'OR',
  },
  {
    name: 'Pennsylvania',
    short_name: 'PA',
  },
  {
    name: 'Rhode Island',
    short_name: 'RI',
  },
  {
    name: 'South Carolina',
    short_name: 'SC',
  },
  {
    name: 'South Dakota',
    short_name: 'SD',
  },
  {
    name: 'Tennessee',
    short_name: 'TN',
  },
  {
    name: 'Texas',
    short_name: 'TX',
  },
  {
    name: 'Utah',
    short_name: 'UT',
  },
  {
    name: 'Vermont',
    short_name: 'VT',
  },
  {
    name: 'Virginia',
    short_name: 'VA',
  },
  {
    name: 'Washington',
    short_name: 'WA',
  },
  {
    name: 'West Virginia',
    short_name: 'WV',
  },
  {
    name: 'Wisconsin',
    short_name: 'WI',
  },
  {
    name: 'Wyoming',
    short_name: 'WY',
  },
  {
    name: 'District of Columbia',
    short_name: 'DC',
  },
]
