import { createSlice } from '@reduxjs/toolkit'
import tenantMembershipsAdapter from '../adapters/tenantMembershipsAdapter'

export default createSlice({
  name: 'tenantMemberships',
  initialState: tenantMembershipsAdapter.getInitialState(),
  reducers: {
    tenantMembershipCreated(state, action) {
      tenantMembershipsAdapter.addOne(state, action.payload)
    },
    tenantMembershipReceived(state, action) {
      tenantMembershipsAdapter.addOne(state, action.payload)
    },
    tenantMembershipsReceived(state, action) {
      tenantMembershipsAdapter.addMany(
        state,
        action.payload.tenant_memberships || [],
      )
    },
    tenantMembershipDeleted(state, action) {
      tenantMembershipsAdapter.removeOne(state, action.payload.id)
    },
  },
})
