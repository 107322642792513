import { createSlice, Slice, EntityState } from '@reduxjs/toolkit'
import candidateMarkdownsAdapter from '../adapters/candidateMarkdownsAdapter'
import { CandidateMarkdown } from 'types'

export const candidateMarkdownsSlice: Slice<EntityState<
  CandidateMarkdown
>> = createSlice({
  name: 'candidateMarkdowns',
  initialState: candidateMarkdownsAdapter.getInitialState(),
  reducers: {
    candidateMarkdownsReceived(state, action) {
      candidateMarkdownsAdapter.addMany(
        state,
        action.payload.candidateMarkdowns,
      )
    },
    candidateMarkdownCreated: candidateMarkdownsAdapter.addOne,
    candidateMarkdownUpdated(state, action) {
      candidateMarkdownsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload },
      })
    },
    candidateMarkdownDeleted(state, action) {
      candidateMarkdownsAdapter.removeOne(state, action.payload.id)
    },
  },
  extraReducers: {
    // This uses a string literal to avoid circular dependencies
    'candidates/candidatesReceived': (state, action) => {
      candidateMarkdownsAdapter.addMany(
        state,
        action.payload.candidate_markdowns || [],
      )
    },
  },
})
