import { createSlice } from '@reduxjs/toolkit'
import organizationsAdapter from '../adapters/organizationsAdapter'

export default createSlice({
  name: 'organizations',
  initialState: organizationsAdapter.getInitialState({
    totalCount: 0,
  }),
  reducers: {
    organizationsReceived(state, action) {
      organizationsAdapter.addMany(state, action.payload.organizations)
      state.totalCount =
        action.payload.totalCount && action.payload.totalCount[0]
    },
    organizationReceived(state, action) {
      organizationsAdapter.addOne(state, action.payload.organizations[0])
    },
  },
})
