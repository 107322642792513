import React, { useState } from 'react'
import classNames from 'classnames'
import styles from './TopNav.module.scss'
import UserMenu from './UserMenu/UserMenu'
import ElectionSwitcher from './ElectionSwitcher/ElectionSwitcher'
import SearchBar from './SearchBar/SearchBar'
import SearchForm from './SearchForm/SearchForm'

interface Props {
  className?: string
}

const TopNav = ({ className }: Props) => {
  let [showSearchForm, setShowSearchForm] = useState<boolean>(false)
  const onClick = () => {
    setShowSearchForm(!showSearchForm)
  }
  return (
    <div className={classNames(styles.root, className)}>
      <ElectionSwitcher
        className={classNames(styles.electionSwitcher, {
          [styles.faded]: showSearchForm,
        })}
      />
      <SearchBar onClick={() => onClick()} className={styles.searchBar} />
      <UserMenu
        className={classNames(styles.userMenu, {
          [styles.faded]: showSearchForm,
        })}
      />
      <SearchForm
        className={classNames(styles.searchForm, {
          [styles.visible]: showSearchForm,
        })}
        closeForm={() => setShowSearchForm(false)}
      />
    </div>
  )
}

export default TopNav
