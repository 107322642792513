import { createSlice } from '@reduxjs/toolkit'
import campaignsAdapter from '../adapters/campaignsAdapter'

export default createSlice({
  name: 'campaigns',
  initialState: campaignsAdapter.getInitialState(),
  reducers: {
    campaignsReceived(state, action) {
      campaignsAdapter.upsertMany(state, action.payload.campaigns)
    },
  },
})
