import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'
import fetchSearchResults from '../../../actions/fetchSearchResults'
import { RootState } from '../../../rootReducer'
import { DEFAULT_PAGE_SIZE, SearchResultType } from 'types'

const mapStateToProps = ({ entities: { campaigns } }: RootState) => ({
  campaigns,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, void, Action>,
) => ({
  onSubmit: async (searchTerm: string, type: SearchResultType, history: any) =>
    await dispatch(
      fetchSearchResults({
        searchTerm,
        type,
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        history,
      }),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)
