import { createSlice } from '@reduxjs/toolkit'
import candidaciesAdapter from '../adapters/candidaciesAdapter'
import endorsementsSlice from './endorsementsSlice'

export default createSlice({
  name: 'candidacies',
  initialState: candidaciesAdapter.getInitialState(),
  reducers: {
    candidaciesReceived(state, action) {
      candidaciesAdapter.addMany(state, action.payload.candidacies)
    },
    candidacyReceived(state, action) {
      candidaciesAdapter.upsertOne(state, action.payload.candidacies[0])
    },
  },
  extraReducers: {
    [endorsementsSlice.actions.endorsementsReceived.toString()]: (
      state,
      action,
    ) => {
      candidaciesAdapter.addMany(state, action.payload.candidacies || [])
    },
  },
})
