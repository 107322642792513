import { PositionMarkdown } from 'types'
import apiFetch from './apiFetch'

import { positionMarkdownsSlice } from 'slices/positionMarkdownsSlice'
import { createAsyncThunk } from '@reduxjs/toolkit'

export default createAsyncThunk(
  'candidateMarkdown/create',
  async (
    params: { values: Partial<PositionMarkdown> },
    { dispatch, rejectWithValue, signal },
  ) => {
    const response = ((await dispatch(
      apiFetch(`/positions/${params.values.position_id}/position_markdowns`, {
        method: 'POST',
        body: JSON.stringify(params.values),
      }),
    )) as unknown) as Response
    const json = await response.json()

    if (!response.ok) {
      return rejectWithValue(json)
    }

    const { positionMarkdownCreated } = positionMarkdownsSlice.actions

    dispatch(positionMarkdownCreated(json))
  },
)
