import React, { ChangeEvent, CSSProperties } from 'react'
import styles from './searchForm.module.scss'
import { Formik, Form, Field } from 'formik'
import searchFormContainer from './searchFormContainer'
import classNames from 'classnames'
import SelectField from './SelectField/SelectField'
import Button from 'components/TopNav/UserMenu/Button/Button'
import { Styles } from 'react-select'
import { useHistory } from 'react-router-dom'

interface Props {
  className?: string
  closeForm: () => void
}

interface SearchFormValues {
  searchTerm: string
  type: string
}

const options = [
  { label: 'Positions', value: 'positions' },
  { label: 'Candidates', value: 'candidates' },
  { label: 'Measures', value: 'measures' },
]

const customStyles: Partial<Styles> = {
  indicatorSeparator: () => ({}),
  indicatorsContainer: (provided: CSSProperties) => ({
    ...provided,
    paddingRight: '1rem',
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    boxShadow: '0 2px 4px 0 rgba(177,177,177,0.5)',
    border: '1px solid #EAEAEA',
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    border: '1px solid #EAEAEA',
    boxShadow: '0 2px 4px 0 rgba(177,177,177,0.5)',
    marginTop: '-4px',
  }),
}

const SearchForm = ({ onSubmit, className, closeForm }: any) => {
  const initialValues: SearchFormValues = { searchTerm: '', type: '' }
  const history = useHistory()
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        closeForm()
        onSubmit(values.searchTerm, values.type, history)
      }}
    >
      {({ errors, setFieldValue, values, handleSubmit, handleChange }) => {
        const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
          setFieldValue('searchTerm', e.target.value)
        }

        return (
          <Form
            className={classNames(styles.form, className)}
            onSubmit={handleSubmit}
          >
            <label className={classNames(styles.keywordLabel, styles.label)}>
              Keywords
            </label>{' '}
            <div className={styles.keyword}>
              <input
                name="searchTerm"
                onChange={handleSearchChange}
                value={values.searchTerm}
                className={styles.input}
                placeholder="Search by location, position, name, issue"
              />
            </div>
            <label className={classNames(styles.dropdownLabel, styles.label)}>
              On the ballot
            </label>
            <Field
              component={SelectField}
              name="type"
              options={options}
              customStyles={customStyles}
              className={styles.dropdown}
            />
            <Button
              type="submit"
              className={classNames(styles.button)}
              disabled={values.searchTerm === '' || values.type === ''}
              theme="secondary"
            >
              See Results
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default searchFormContainer(SearchForm)
