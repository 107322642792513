import React from 'react'
import { FieldProps } from 'formik'
import Select, { Styles } from 'react-select'
import DropdownIndicator from 'components/TopNav/DropdownIndicator/DropdownIndicator'

const SelectField = ({
  field, // { name, value, onChange, onBlur }
  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  onChange: (() => void) | null
  options: Array<{ value: string; label: string }>
  customStyles: Partial<Styles>
  isMulti: boolean
}) => {
  const { options, customStyles, onChange, isMulti = false } = props

  const defaultOnChange = (option: any) => {
    setFieldValue(field.name, option ? (option as any).value : null)
  }

  return (
    <Select
      {...field}
      {...props}
      styles={customStyles}
      components={{ DropdownIndicator }}
      options={options}
      value={
        (options
          ? options.find((option) => option.value === field.value) === undefined
            ? ''
            : options.find((option) => option.value === field.value)
          : '') as any
      }
      onChange={onChange ? onChange : defaultOnChange}
    />
  )
}
export default SelectField
