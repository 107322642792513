import React from 'react'
import history from './browserHistory'
import store from './store'
import createCurrentUser from './actions/createCurrentUser'
import { Provider } from 'react-redux'
import Auth0Provider from 'components/Auth0Provider/Auth0Provider'
import AppErrorBoundary from './components/AppErrorBoundary/AppErrorBoundary'
import Main from 'components/Main/Main'

const App: React.FC = () => {
  // A function that routes the user to the right place
  // after login
  const onRedirectCallback = (result: RedirectLoginResult) => {
    history.push(result.appState?.targetUrl || window.location.pathname)

    // Fix type: it's a ThunkAction
    store.dispatch<any>(createCurrentUser())
  }

  return (
    <AppErrorBoundary>
      <Provider store={store}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN!}
          client_id={process.env.REACT_APP_AUTH0_CLIENT_ID!}
          redirect_uri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
        >
          <Main />
        </Auth0Provider>
      </Provider>
    </AppErrorBoundary>
  )
}

export default App
