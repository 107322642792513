import { createContext } from 'react'

interface Auth0User extends Omit<IdToken, '__raw'> {
  'https://ballotready.org/claims/superuser': boolean
  'https://ballotready.org/claims/tenant_id': number | null
  'https://ballotready.org/claims/election_id': number | null
}

interface Auth0Context {
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>
  handleRedirectCallback(): void
  isAuthenticated: boolean
  loading: boolean
  loginWithPopup(o?: PopupLoginOptions): Promise<void>
  loginWithRedirect(o?: RedirectLoginOptions): Promise<void>
  logout(o?: LogoutOptions): void
  popupOpen: boolean
  user?: Auth0User
}

export const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

const Auth0Context = createContext<Auth0Context>({
  getIdTokenClaims: () => new Promise<IdToken>(() => {}),
  getTokenSilently: () => new Promise<string | undefined>(() => {}),
  getTokenWithPopup: () => new Promise<string | undefined>(() => {}),
  handleRedirectCallback: DEFAULT_REDIRECT_CALLBACK,
  isAuthenticated: false,
  loading: true,
  loginWithPopup: () => new Promise<void>(() => {}),
  loginWithRedirect: () => new Promise<void>(() => {}),
  logout: () => {},
  popupOpen: false,
})

export default Auth0Context
