import { createSlice } from '@reduxjs/toolkit'
import electionsAdapter from '../adapters/electionsAdapter'
import { Position } from '../types'
import positionsSlice from './positionsSlice'

export default createSlice({
  name: 'elections',
  initialState: electionsAdapter.getInitialState<{
    currentTenantId: null | number
  }>({
    currentTenantId: null,
  }),
  reducers: {
    electionsReceived(state, action) {
      electionsAdapter.addMany(state, action.payload.elections || [])
    },
  },
  extraReducers: {
    [positionsSlice.actions.positionsReceived.toString()]: (state, action) => {
      electionsAdapter.updateOne(state, {
        id: action.payload.electionId[0],
        changes: {
          positions: action.payload.positions
            ? action.payload.positions?.map((p: Position) => p.id)
            : [],
        },
      })
    },
  },
})
