import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'
import { RootState } from '../../../../rootReducer'
import { PositionMarkdown } from 'types'
import createPositionMarkdown from 'actions/createPositionMarkdown'
import updatePositionMarkdown from 'actions/updatePositionMarkdown'
import deletePositionMarkdown from 'actions/deletePositionMarkdown'

const mapStateToProps = ({
  entities: { elections, candidacies },
}: RootState) => ({
  elections,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, void, Action>,
) => ({
  handleCreatePositionMarkdown: async (
    values: any,
    positionId: number,
    markdownType: 'name' | 'description',
  ) => {
    const tosend: Partial<PositionMarkdown> = {
      position_id: positionId,
      markdown_type: markdownType,
      locale: values.locale,
      markdown_blob:
        markdownType === 'name' ? values.customName : values.customDescription,
    }
    await dispatch(createPositionMarkdown({ values: tosend }))
  },
  handleUpdatePositionMarkdown: async (
    id: number,
    values: any,
    positionId: number,
    markdownType: 'name' | 'description',
  ) => {
    const tosend: Partial<PositionMarkdown> = {
      position_id: positionId,
      markdown_type: markdownType,
      locale: values.locale,
      markdown_blob:
        markdownType === 'name' ? values.customName : values.customDescription,
    }
    await dispatch(updatePositionMarkdown({ id: id, values: tosend }))
  },
  handleDeletePositionMarkdown: async (id: number, positionId: number) => {
    await dispatch(deletePositionMarkdown({ id: id, position_id: positionId }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
