import { createSlice } from '@reduxjs/toolkit'
import educationAdapter from '../adapters/educationAdapter'
import candidatesSlice from './candidatesSlice'

export default createSlice({
  name: 'education',
  initialState: educationAdapter.getInitialState(),
  reducers: {
    educationReceived(state, action) {
      educationAdapter.addMany(state, action.payload.education)
    },
  },
  extraReducers: {
    [candidatesSlice.actions.candidatesReceived.toString()]: (
      state,
      action,
    ) => {
      educationAdapter.addMany(state, action.payload.education || [])
    },
  },
})
