import { createSlice, Slice, EntityState } from '@reduxjs/toolkit'
import positionMarkdownsAdapter from '../adapters/positionMarkdownsAdapter'
import { PositionMarkdown } from 'types'

export const positionMarkdownsSlice: Slice<EntityState<
  PositionMarkdown
>> = createSlice({
  name: 'positionMarkdowns',
  initialState: positionMarkdownsAdapter.getInitialState(),
  reducers: {
    positionMarkdownsReceived(state, action) {
      positionMarkdownsAdapter.addMany(state, action.payload.positionMarkdowns)
    },
    positionMarkdownCreated: positionMarkdownsAdapter.addOne,
    positionMarkdownUpdated(state, action) {
      positionMarkdownsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { ...action.payload },
      })
    },
    positionMarkdownDeleted(state, action) {
      positionMarkdownsAdapter.removeOne(state, action.payload.id)
    },
  },
  extraReducers: {
    // This uses a string literal to avoid circular dependencies
    'positions/positionsReceived': (state, action) => {
      positionMarkdownsAdapter.addMany(
        state,
        action.payload.position_markdowns || [],
      )
    },
  },
})
