import { combineReducers } from 'redux'
import electionsSlice from '../slices/electionsSlice'
import tenantsSlice from '../slices/tenantsSlice'
import usersSlice from '../slices/usersSlice'
import tenantMembershipsSlice from '../slices/tenantMembershipsSlice'
import candidaciesSlice from '../slices/candidaciesSlice'
import positionsSlice from '../slices/positionsSlice'
import candidatesSlice from 'slices/candidatesSlice'
import { candidateMarkdownsSlice } from 'slices/candidateMarkdownsSlice'
import educationSlice from 'slices/educationSlice'
import experienceSlice from 'slices/experienceSlice'
import installsSlice from 'slices/installsSlice'
import campaignsSlice from 'slices/campaignsSlice'
import eventsSlice from 'slices/eventsSlice'
import messagesSlice from 'slices/messagesSlice'
import messageTranslationsSlice from 'slices/messageTranslationsSlice'
import { positionMarkdownsSlice } from 'slices/positionMarkdownsSlice'
import organizationsSlice from 'slices/organizationsSlice'
import endorsementsSlice from 'slices/endorsementsSlice'

export default combineReducers({
  campaigns: campaignsSlice.reducer,
  candidacies: candidaciesSlice.reducer,
  candidate_markdowns: candidateMarkdownsSlice.reducer,
  education: educationSlice.reducer,
  elections: electionsSlice.reducer,
  endorsements: endorsementsSlice.reducer,
  events: eventsSlice.reducer,
  experience: experienceSlice.reducer,
  organizations: organizationsSlice.reducer,
  positions: positionsSlice.reducer,
  messages: messagesSlice.reducer,
  messageTranslations: messageTranslationsSlice.reducer,
  tenant_memberships: tenantMembershipsSlice.reducer,
  tenants: tenantsSlice.reducer,
  candidates: candidatesSlice.reducer,
  users: usersSlice.reducer,
  installs: installsSlice.reducer,
  position_markdowns: positionMarkdownsSlice.reducer,
})
