export const locales = [
  { name: 'arabic', key: 'ar' },
  { name: 'mandarin', key: 'zh' },
  { name: 'cantonese', key: 'zh-TW' },
  { name: 'creole', key: 'cr' },
  { name: 'french', key: 'fr' },
  { name: 'hmong', key: 'hmn' },
  { name: 'korean', key: 'ko' },
  { name: 'spanish', key: 'es' },
  { name: 'tagalog', key: 'tl' },
  { name: 'english', key: 'en' },
]
