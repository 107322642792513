import { createSlice } from '@reduxjs/toolkit'
import { Election, Install } from '../types'
import electionsSlice from './electionsSlice'
import tenantsAdapter from '../adapters/tenantsAdapter'
import installsSlice from './installsSlice'
import tenantMembershipsSlice from './tenantMembershipsSlice'

export default createSlice({
  name: 'tenants',
  initialState: tenantsAdapter.getInitialState({
    totalCount: 0,
  }),
  reducers: {
    tenantsReceived(state, action) {
      tenantsAdapter.addMany(state, action.payload.tenants)
      state.totalCount =
        action.payload.totalCount && action.payload.totalCount[0]
    },
  },
  extraReducers: {
    [electionsSlice.actions.electionsReceived.toString()]: (state, action) => {
      tenantsAdapter.updateOne(state, {
        id: action.payload.tenantId,
        changes: {
          elections: action.payload.elections?.map((e: Election) => e.id),
        },
      })
    },
    [installsSlice.actions.installsReceived.toString()]: (state, action) => {
      tenantsAdapter.updateOne(state, {
        id: action.payload.tenantId,
        changes: {
          installs: action.payload.installs.map((i: Install) => i.id),
        },
      })
    },
    [tenantMembershipsSlice.actions.tenantMembershipReceived.toString()]: (
      state,
      action,
    ) => {
      const tenant_id = action.payload.tenant_id
      const newTenantMemberships = state.entities[
        tenant_id
      ]?.tenant_memberships.concat(action.payload.id)
      tenantsAdapter.updateOne(state, {
        id: action.payload.tenant_id,
        changes: {
          tenant_memberships: newTenantMemberships,
        },
      })
    },
    [tenantMembershipsSlice.actions.tenantMembershipDeleted.toString()]: (
      state,
      action,
    ) => {
      const indexToDelete = state.entities[
        action.payload.tenant_id
      ]?.tenant_memberships.indexOf(action.payload.id)
      if (indexToDelete !== undefined) {
        const newTenantMemberships = state.entities[
          action.payload.tenant_id
        ]?.tenant_memberships.splice(indexToDelete, 1)
        tenantsAdapter.updateOne(state, {
          id: action.payload.id,
          changes: { tenant_memberships: newTenantMemberships },
        })
      }
    },
    [tenantMembershipsSlice.actions.tenantMembershipCreated.toString()]: (
      state,
      action,
    ) => {
      const newTenantMemberships = state.entities[
        action.payload.tenant_id
      ]?.tenant_memberships.concat(action.payload.id)

      tenantsAdapter.updateOne(state, {
        id: action.payload.tenant_id,
        changes: { tenant_memberships: newTenantMemberships },
      })
    },
  },
})
