import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'
import styles from './Pagination.module.scss'

export interface Props {
  currentPageIndex: number
  totalRows: number
  rowsPerPage: number
  paginate: (newPageIndex: number) => void
}

const Pagination: React.FC<Props> = ({
  currentPageIndex,
  rowsPerPage,
  paginate,
  totalRows,
}) => {
  let pageNumbers = []
  const totalPages = Math.ceil(totalRows / rowsPerPage)
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i)
  }
  const firstItemNumber = currentPageIndex * rowsPerPage + 1
  const lastItemNumber =
    currentPageIndex === totalPages - 1
      ? totalRows
      : (currentPageIndex + 1) * rowsPerPage

  return (
    <div className={styles.container}>
      <div>{`${firstItemNumber}-${lastItemNumber} of ${totalRows}`}</div>
      {currentPageIndex > 0 && (
        <button
          onClick={() => paginate(currentPageIndex - 1)}
          className={styles.pageNumber}
        >
          <FontAwesomeIcon icon={faChevronLeft} className={styles.arrow} />
        </button>
      )}
      {currentPageIndex < totalPages - 1 && (
        <button
          onClick={() => paginate(currentPageIndex + 1)}
          className={styles.pageNumber}
        >
          <FontAwesomeIcon icon={faChevronRight} className={styles.arrow} />
        </button>
      )}
    </div>
  )
}

export default Pagination
