import { createAsyncThunk } from '@reduxjs/toolkit'
import apiFetch from './apiFetch'
import positionsSlice from '../slices/positionsSlice'
import qs from 'qs'
import { positionListSchema, positionSchema } from 'schema'
import customNormalize from 'utils/customNormalize'

interface fetchPositionsParams {
  electionId: number
  pageIndex: number
  pageSize: number
  level?: string
  name?: string
  state?: string
  id?: string
  county?: string
}

export default createAsyncThunk(
  'positions/fetch',
  async (
    {
      electionId,
      pageIndex,
      pageSize,
      name,
      level,
      county,
      state,
      id,
    }: fetchPositionsParams,
    { dispatch, signal },
  ) => {
    const params = qs.stringify({
      page: pageIndex + 1,
      page_size: pageSize,
      'q[name_cont]': name,
      'q[level_eq]': level,
      'q[state_eq]': state,
      'q[counties_includes]': county,
      id,
    })
    const response = ((await dispatch(
      apiFetch(`/elections/${electionId}/positions/?${params}`, {
        signal,
      }),
    )) as unknown) as Response

    const json = await response.json()
    const normalized = customNormalize(json, positionListSchema)
    normalized['electionId'] = [electionId]
    normalized['totalCount'] = [response.headers.get('X-Total')]
    return dispatch(positionsSlice.actions.positionsReceived(normalized))
  },
)
